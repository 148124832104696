/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export interface ChartItem {
  month: Date;
  percent: string;
  total: string;
  value: string;
}

type IChart = {
  data: any;
  title: string;
  value: number;
  isPercent: boolean;
  subTitle1?: string;
  subTitle2?: string;
};

const CustomizedDot = (props: any) => {
  const { cx, cy, stroke, payload, value } = props;
  return (
    <circle
      cx={cx}
      cy={cy}
      r={3}
      stroke="#213B54"
      strokeWidth={2}
      fill="#213B54"
      className="tooltiptext"
    />
  );
};

const Chart = ({
  data,
  title,
  value,
  isPercent,
  subTitle2,
  subTitle1,
}: IChart) => {
  const [lineChartData, setLineChartData] = useState<any>();
  const CustomizedTick = (props: any) => {
    const { x, y, stroke, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        {payload.value > 0 && (
          <text
            x={0}
            y={0}
            dy={4}
            fill="#999999"
            fontSize={'8px'}
            fontWeight={500}
            fontFamily="Plus Jakarta Sans"
          >
            <tspan textAnchor="middle" x="-10">
              {isPercent ? payload.value + '%' : payload.value}
            </tspan>
          </text>
        )}
      </g>
    );
  };
  const CustomTooltip = (props: any) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <div
          className={
            isPercent
              ? lineChartData?.activeTooltipIndex === 5
                ? 'tooltip-text-with-sub-title-latest'
                : lineChartData?.activeTooltipIndex === 0
                ? 'tooltip-text-with-sub-title-first'
                : 'tooltip-text-with-sub-title'
              : lineChartData?.activeTooltipIndex === 5
              ? 'tooltiptextV2'
              : 'tooltiptext'
          }
        >
          <Stack
            sx={{
              backgroundColor: '#000000B2',
              width: 'auto',
              height: 'auto',
              borderRadius: '5px',
              padding: '8px',
            }}
          >
            <Stack>
              <Typography
                sx={{
                  fontWeight: 400,
                  color: '#FFFFFF',
                  fontSize: '14px',
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: '#FFFFFF',
                  fontSize: '16px',
                }}
              >
                {isPercent
                  ? Number(props.payload[0]?.payload.percent).toFixed(1) + '%'
                  : props.payload[0]?.payload.percent}
              </Typography>
            </Stack>
            {isPercent && (
              <Stack
                sx={{
                  borderTop: '1px solid #EAEAEA',
                }}
                paddingY={'8px'}
              >
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  spacing={1}
                  paddingBottom={'10px'}
                >
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '10px',
                      letterSpacing: '0.15px',
                    }}
                  >
                    {subTitle1}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '10px',
                      letterSpacing: '0.15px',
                    }}
                  >
                    {props.payload[0]?.payload.value}
                  </Typography>
                </Stack>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  spacing={1}
                >
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '10px',
                      letterSpacing: '0.15px',
                    }}
                  >
                    {subTitle2}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '10px',
                      letterSpacing: '0.15px',
                    }}
                  >
                    {props.payload[0]?.payload.total}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </div>
      );
    }
    return null;
  };

  const getDomain = () => {
    const min =
      Math.min(...(data?.map((item: ChartItem) => Number(item.percent)) || 0)) <
      2
        ? 0
        : Math.floor(
            Math.min(
              ...(data?.map((item: ChartItem) => Number(item.percent)) || 0),
            ) / 5,
          ) * 5;
    const max =
      Math.max(
        ...(data?.map((item: ChartItem) => Number(item.percent)) || 100),
      ) === 0
        ? 100
        : Math.ceil(
            (Math.max(
              ...(data?.map((item: ChartItem) => Number(item.percent)) || 100),
            ) +
              2) /
              5,
          ) * 5;
    const tickArray = [
      0,
      Math.trunc(max / 5),
      Math.trunc(max / 2.5),
      Math.trunc((3 * max) / 5),
      Math.trunc((4 * max) / 5),
      max,
    ];
    return tickArray;
  };

  return (
    <Stack
      sx={{
        height: '320px',
        width: { xs: '100%', sm: '40%', xl: '30%' },
        margin: '12px',
        backgroundColor: '#FAF7F4',
        borderRadius: '25px',
        padding: '24px',
        boxShadow: '2px 3px 4px 0px #00000040',
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 700,
          color: '#213B54',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '36px',
          fontWeight: 700,
          color: '#213B54',
        }}
      >
        {isPercent ? Number(value).toFixed(1) + '%' : value}
      </Typography>

      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: 600,
          color: '#737373',
          lineHeight: '16px',
          marginTop: '4px',
        }}
      >
        ROLLING 6-MONTH TREND
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 15,
            right: 30,
            left: -30,
            bottom: -15,
          }}
          onMouseMove={(data) => {
            setLineChartData(data);
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="month"
            tickLine={false}
            fontSize={'10px'}
            height={32}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            type="number"
            tick={<CustomizedTick />}
            allowDecimals={false}
            interval={0}
            ticks={getDomain()}
            tickCount={getDomain().length}
            scale={'time'}
          />
          <Tooltip
            cursor={false}
            position={{
              x:
                lineChartData?.activeTooltipIndex === 5
                  ? isPercent
                    ? lineChartData?.chartX - 160
                    : lineChartData?.chartX - 90
                  : lineChartData?.activeTooltipIndex === 0
                  ? isPercent
                    ? lineChartData?.chartX - 50
                    : lineChartData?.chartX - 20
                  : isPercent
                  ? lineChartData?.chartX - 90
                  : lineChartData?.chartX - 20,

              y: isPercent
                ? lineChartData?.chartY - 125
                : lineChartData?.chartY - 90,
            }}
            content={<CustomTooltip />}
          />
          <Line
            fill="#213B54"
            type="monotone"
            dataKey="percent"
            strokeWidth={3}
            stroke="#213B54"
            activeDot={{ stroke: '#213B54', strokeWidth: 2, r: 3 }}
            dot={<CustomizedDot />}
          />
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default Chart;
